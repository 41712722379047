import { Category } from "../category";
import { Combination } from "../../combination";
import { isEqual } from "lodash";

export class LargeStraight extends Category {
  name = "LargeStraight";

  isValid(combination: Combination): boolean {
    return (
      isEqual(combination.sort(), [1, 2, 3, 4, 5]) ||
      isEqual(combination.sort(), [2, 3, 4, 5, 6])
    );
  }

  computeScore(_: Combination): number {
    return 40;
  }
}
