import { Category } from "../category";
import { Combination } from "../../combination";

export class FourOfAKind extends Category {
  name = "FourOfAKind";

  isValid(combination: Combination): boolean {
    const duplicates = combination.getDuplicates();

    return Array.from(duplicates.values()).some((number) => number >= 4);
  }

  computeScore(combination: Combination): number {
    return combination.value.reduce((score, face) => score + face, 0);
  }
}
