import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { selectCurrentPlayer } from "../store/slices/gameSlice";
import { selectSelfPlayerId } from "../store/slices/settingsSlice";
import { ONLINE_PATHNAME } from "../constants";

export function useCanPlayInOnlineMode(): boolean {
  const isOnlineMode = useIsOnlineMode();
  const currentPlayer = useAppSelector(selectCurrentPlayer);
  const selfPlayerId = useAppSelector(selectSelfPlayerId);

  return useMemo(
    () => (isOnlineMode ? selfPlayerId === currentPlayer?.id : true),
    [isOnlineMode, currentPlayer, selfPlayerId]
  );
}

export function useIsOnlineMode(): boolean {
  const { pathname } = useLocation();

  return useMemo(() => ONLINE_PATHNAME === pathname, [pathname]);
}
