import { Category } from "../category";
import { Combination } from "../../combination";

export class Chance extends Category {
  name = "Chance";

  isValid(_: Combination): boolean {
    return true;
  }

  computeScore(combination: Combination): number {
    return combination.value.reduce((score, face) => score + face, 0);
  }
}
