import assert from "assert";
import { DiceFace } from "./dice-face";

export class Combination {
  private readonly combination: PrimitiveCombination;

  private constructor(combination: PrimitiveCombination) {
    this.combination = [...combination];
  }

  static of(combination: DiceFace[]): Combination {
    assertCombination(combination);

    return new Combination(combination);
  }

  get value(): PrimitiveCombination {
    return this.combination;
  }

  sort(): PrimitiveCombination {
    const clonedCombination = Combination.of(this.combination);
    return clonedCombination.value.sort();
  }

  getDuplicates(): Map<DiceFace, number> {
    const duplicates = new Map<DiceFace, number>();

    this.combination.forEach((face) => {
      duplicates.set(face, (duplicates.get(face) || 0) + 1);
    });

    return duplicates;
  }
}

export type PrimitiveCombination = [
  DiceFace,
  DiceFace,
  DiceFace,
  DiceFace,
  DiceFace
];

function assertCombination(
  combination: DiceFace[]
): asserts combination is PrimitiveCombination {
  assert(combination.length === 5);
}
