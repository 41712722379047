import React from "react";
import { useAppSelector } from "../../store/hooks";
import {
  selectCurrentPlayer,
  selectPlayers,
} from "../../store/slices/gameSlice";

export function HighlightCurrentPlayer() {
  const players = useAppSelector(selectPlayers);
  const currentPlayer = useAppSelector(selectCurrentPlayer);

  return players.length > 1 ? (
    <colgroup>
      <col />
      {players.map((p, i) => (
        <col
          key={i}
          className={
            currentPlayer?.id === p.id ? "has-background-primary-light" : ""
          }
        />
      ))}
    </colgroup>
  ) : null;
}
