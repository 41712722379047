import React, { useContext, useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import {
  selectBestPlayer,
  selectCurrentPlayer,
  selectIsGameFinished,
  selectPlayers,
} from "../store/slices/gameSlice";
import { toast } from "react-hot-toast";
import { translate } from "../services/i18n/i18n";
import { WelcomeModal } from "./modals/WelcomeModal";
import { selectDisplayWelcomeModal } from "../store/slices/settingsSlice";
import { sample } from "lodash";
import ThemeContext from "../services/contexts/ThemeContext";
import variables from "../stylesheets/variables.scss";
import { getPlayerUsername } from "../services/get-player-username";
import { WinnerModal } from "./modals/WinnerModal";

export function GameplayHooks() {
  const players = useAppSelector(selectPlayers);
  const currentPlayer = useAppSelector(selectCurrentPlayer);
  const displayWelcomeModal = useAppSelector(selectDisplayWelcomeModal);
  const isGameFinished = useAppSelector(selectIsGameFinished);
  const bestPlayer = useAppSelector(selectBestPlayer);
  const theme = useContext(ThemeContext);
  const [displayWinnerModal, setDisplayWinnerModal] = useState(false);

  useEffect(() => {
    if (currentPlayer && players.length > 1) {
      toast(`${translate("turn")} ${getPlayerUsername(currentPlayer)}`);

      const columnSketch = document.querySelector(".column-sketch");
      const tableWrapper = document.querySelector(".table-wrapper");
      const playerColumn = document.querySelector<HTMLElement>(
        `#player-${currentPlayer.id}`
      );

      if (tableWrapper && playerColumn && columnSketch) {
        const borderLeftSize = 4;
        columnSketch.scrollTo({
          left: playerColumn.offsetLeft - playerColumn.offsetWidth + 10,
          top: 0,
          behavior: "smooth",
        });
        tableWrapper.scrollTo({
          left:
            playerColumn.offsetLeft - playerColumn.offsetWidth - borderLeftSize,
          top: 0,
          behavior: "smooth",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlayer?.id]);

  useEffect(() => {
    let intervalId: NodeJS.Timer;

    if (isGameFinished && bestPlayer) {
      setDisplayWinnerModal(true);

      intervalId = setInterval(() => {
        const value =
          sample(["red", "green", "blue", "purple", "black"]) || "red";

        setColor(value);
      }, 500);
    }

    return () => {
      setColor(variables.primary);
      return clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGameFinished]);

  function setColor(color: string) {
    document.documentElement.style.setProperty("--dice-color", color);
    theme.setColor(color);
  }

  return (
    <>
      {displayWelcomeModal && <WelcomeModal />}
      {displayWinnerModal && (
        <WinnerModal onClose={() => setDisplayWinnerModal(false)} />
      )}
    </>
  );
}
