import SocketContext, {
  SocketProvider,
} from "../services/contexts/SocketContext";
import { Game } from "./Game";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "../services/use-query";
import { translate } from "../services/i18n/i18n";
import { v4 as uuid } from "uuid";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  GameState,
  overrideGame,
  resetGame,
  selectGame,
} from "../store/slices/gameSlice";
import { store } from "../store/store";
import { Link } from "react-router-dom";
import { QrCodeModal } from "./modals/QrCodeModal";
import { ONLINE_PATHNAME } from "../constants";
import { throttle } from "lodash";
import { setNumberOfOnlinePlayers } from "../store/slices/settingsSlice";

export function OnlineGame() {
  const search = useQuery();

  const dispatch = useAppDispatch();
  const game = useAppSelector(selectGame);
  const socket = useContext(SocketContext);
  const [displayQrCodeModal, setDisplayQrCodeModal] = useState(true);
  const [firstScreenLaunch, setFirstScreenLaunch] = useState(true);

  const depsSendGameOnlyIfGameChanged = useMemo(
    () => btoa(JSON.stringify(game)),
    [game]
  );

  useEffect(() => {
    if (firstScreenLaunch) {
      setFirstScreenLaunch(false);
    } else {
      socket.emit("send-game", { game: store.getState().game });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depsSendGameOnlyIfGameChanged]);

  useEffect(() => {
    socket.emit("join", {
      room: search.get("room"),
    });
    socket.on(
      "game-updated",
      throttle((data: { game: GameState; socketId?: string }) => {
        if (data.socketId) {
          if (data.socketId === socket.id) {
            console.log("game init");
            dispatch(overrideGame(data.game));
          }
        } else {
          console.log("game updated");
          dispatch(overrideGame(data.game));
        }
      }, 20)
    );
    socket.on("new-player", (data: { socketId: string }) => {
      socket.emit("send-game", {
        game: store.getState().game,
        socketId: data.socketId,
      });
    });
    socket.on("number-of-players", (data: { numberOfPlayers: number }) => {
      dispatch(setNumberOfOnlinePlayers(data.numberOfPlayers));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("visibilitychange", onWindowFocus);
    return () => {
      window.removeEventListener("visibilitychange", onWindowFocus);
    };
  }, []);

  function onWindowFocus() {
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      window.location.reload();
    }
  }

  return (
    <SocketProvider value={socket}>
      {search.get("room") ? (
        <>
          <Game />
          {displayQrCodeModal && (
            <QrCodeModal onClose={() => setDisplayQrCodeModal(false)} />
          )}
        </>
      ) : (
        <div className="pt-6 has-text-centered">
          <Link
            onClick={() => dispatch(resetGame())}
            to={`/${ONLINE_PATHNAME}?room=${uuid()}`}
            className="button is-large is-primary"
          >
            {translate("new-game")}
          </Link>
        </div>
      )}
    </SocketProvider>
  );
}
