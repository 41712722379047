import { Category } from "../category";
import { Combination } from "../../combination";

export class FullHouse extends Category {
  name = "FullHouse";

  isValid(combination: Combination): boolean {
    const duplicates = combination.getDuplicates();

    const threeOfAKind = Array.from(duplicates.entries()).find(
      ([_, number]) => number === 3
    );
    if (!threeOfAKind) {
      return false;
    }
    duplicates.delete(threeOfAKind[0]);

    return Array.from(duplicates.values()).some((number) => number === 2);
  }

  computeScore(_: Combination): number {
    return 25;
  }
}
