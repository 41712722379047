import { ConfigLine } from "./ConfigLine";
import { CategoryLine } from "./CategoryLine";
import { SubtotalLine } from "./SubtotalLine";
import { TotalLine } from "./TotalLine";
import React from "react";
import {
  LOWER_SECTION_CATEGORIES,
  UPPER_SECTION_CATEGORIES,
} from "../../domain/all-categories";
import { useAppSelector } from "../../store/hooks";
import { selectPlayers } from "../../store/slices/gameSlice";
import { HighlightCurrentPlayer } from "./HighlightCurrentPlayer";

export function Sketch() {
  const players = useAppSelector(selectPlayers);

  return (
    <div className="sketch">
      <div className="table-wrapper">
        <table className="table is-hoverable is-fullwidth is-vcentered">
          <HighlightCurrentPlayer />
          <tbody>
            <ConfigLine />
            {UPPER_SECTION_CATEGORIES.map((category, i) => (
              <CategoryLine key={i} category={category} />
            ))}
          </tbody>
          <tbody>
            <SubtotalLine />
          </tbody>
          <tbody className="table-clear">
            <tr>
              <td colSpan={players.length + 1} />
            </tr>
          </tbody>
          <tbody>
            {LOWER_SECTION_CATEGORIES.map((category, i) => (
              <CategoryLine key={i} category={category} />
            ))}
          </tbody>
          <tbody>
            <TotalLine />
          </tbody>
        </table>
      </div>
    </div>
  );
}
