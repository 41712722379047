import React from "react";
import { Category } from "../../domain/categories";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  nextPlayer,
  selectCurrentCombination,
  selectCurrentPlayer,
  selectPlayers,
  setPlayerScore,
} from "../../store/slices/gameSlice";
import { CategoryDisplay } from "./CategoryDisplay";
import { useCanPlayInOnlineMode } from "../../services/use-can-play-in-online-mode";
import { translate } from "../../services/i18n/i18n";
import { Computation } from "../../domain/computation";

export function CategoryLine({ category }: { category: Category }) {
  const dispatch = useAppDispatch();
  const canPlayInOnlineMode = useCanPlayInOnlineMode();
  const currentPlayer = useAppSelector(selectCurrentPlayer);
  const players = useAppSelector(selectPlayers);
  const currentCombination = useAppSelector(selectCurrentCombination);

  function validCombination() {
    if (!currentCombination || !currentPlayer || !canValidateCombination()) {
      return;
    }

    dispatch(
      setPlayerScore({
        category: category,
        combination: currentCombination,
      })
    );

    dispatch(nextPlayer());
  }

  function shouldHelpUserToValidate() {
    return (
      currentCombination &&
      category.isValid(currentCombination) &&
      canValidateCombination()
    );
  }

  function canValidateCombination() {
    const scoreNotAlreadyValidated =
      Computation.getPlayerScore(category, currentPlayer) === null;

    return scoreNotAlreadyValidated && canPlayInOnlineMode;
  }

  return (
    <tr onClick={validCombination} className="is-clickable">
      <td
        className={`has-tooltip-right ${
          shouldHelpUserToValidate() ? "has-text-primary is-glow" : ""
        }`}
        data-tooltip={
          translate(
            `combination.${category.name}.description` as "combination.ThreeOfAKind.description"
          ) +
          "\n" +
          translate("score") +
          translate(
            `combination.${category.name}.score` as "combination.ThreeOfAKind.score"
          )
        }
      >
        <CategoryDisplay category={category} />
      </td>

      {players.map((player, playerIndex) => {
        return (
          <td key={playerIndex}>
            {canValidateCombination() && currentPlayer?.id === player.id ? (
              <span className="has-text-grey-lighter has-text-weight-bold">
                {currentCombination &&
                  Computation.getCombinationScore(category, currentCombination)}
              </span>
            ) : (
              <span className="has-text-cursive">
                {Computation.getPlayerScore(category, player)}
              </span>
            )}
          </td>
        );
      })}
    </tr>
  );
}
