import { BoxProps, CollideEvent, Triplet, useBox } from "@react-three/cannon";
import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";
import { createDiceFaceTexture } from "../../services/create-dice-face-texture";
import { clamp, throttle } from "lodash";
import rollSound from "./assets/roll.mp3";
import { DiceFace } from "../../domain/dice-face";
import { getRollState, RollState } from "./RollState";

const rollAudio = new Audio(rollSound);

export type DiceActions = {
  roll: () => void;
};

function getInitialState(diceIndex: number): RollState {
  return {
    position: [(diceIndex - 2) * 1.5, diceIndex, 0],
    velocity: [0, 0, 0],
    angularVelocity: [0, 0, 0],
    rotation: [0, 0, 0],
  };
}

export const Dice = forwardRef(function Dice(
  props: BoxProps & {
    selected: boolean;
    diceIndex: number;
    onRoll: (rollState: RollState) => void;
    onRolled: (face: DiceFace) => void;
    imageFaces: HTMLImageElement[];
    rollState?: RollState;
    color: string;
  },
  ref: ForwardedRef<DiceActions>
) {
  const {
    selected,
    diceIndex,
    onRoll,
    onRolled,
    imageFaces,
    rollState,
    color,
  } = props;
  const scene = useThree((state) => state.scene);
  const { width, height } = useThree((state) => state.viewport);
  const [isRolling, setIsRolling] = useState(false);
  const [boxRef, api] = useBox(() => ({
    mass: selected ? 0 : 100,
    onCollide: (e: CollideEvent) => {
      if (e.body.userData.isSound) {
        soundImpact(e.contact.impactVelocity);
      }
    },
    ...getInitialState(diceIndex),
  }));
  const LAYER_CHANNEL_NUMBER = 1;

  const textures = useMemo(() => {
    return Array.from(Array(6)).map((_, i) =>
      createDiceFaceTexture(imageFaces[i], selected ? "red" : color)
    );
  }, [imageFaces, selected, color]);

  const soundImpact = useCallback((velocity: number) => {
    rollAudio.currentTime = 0;
    rollAudio.volume = clamp(velocity / 10, 0, 1);
    rollAudio.play();
  }, []);

  const applyRollState = useCallback(
    (state: RollState) => {
      if (state === null) {
        api.position.set(100, -100, 0);
      } else {
        api.wakeUp();
        api.position.set(...state.position);
        api.velocity.set(...state.velocity);
        api.angularVelocity.set(...state.angularVelocity);
        api.rotation.set(...state.rotation);
      }
    },
    [api]
  );

  const getDiceFace = useCallback((): DiceFace => {
    if (boxRef.current) {
      const boxPosition = new THREE.Vector3();
      boxPosition.setFromMatrixPosition(boxRef.current.matrixWorld);
      const pointAboveBoxPosition = new THREE.Vector3(
        boxPosition.x,
        100,
        boxPosition.z
      );

      const ray = new THREE.Raycaster(
        pointAboveBoxPosition,
        boxPosition.clone().sub(pointAboveBoxPosition).normalize()
      );
      ray.layers.set(LAYER_CHANNEL_NUMBER);
      const intersects = ray.intersectObjects(scene.children);

      const face = intersects[0].face;
      if (!face) {
        throw new Error("Face is not found");
      }

      return (face.materialIndex + 1) as DiceFace;
    }

    throw new Error("Box not initialized");
  }, [boxRef, scene.children]);

  useEffect(() => {
    const unsubscribe = api.velocity.subscribe(
      throttle((velocity: Triplet) => {
        if (velocity.every((v) => Math.abs(v) < 0.01) && isRolling) {
          setIsRolling(false);

          onRolled(getDiceFace());
          api.sleep();
        }
      }, 10)
    );
    return unsubscribe;
  }, [api, getDiceFace, isRolling, onRolled]);

  useEffect(() => {
    if (rollState !== undefined) {
      applyRollState(rollState);
    }
  }, [applyRollState, rollState]);

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.layers.enable(LAYER_CHANNEL_NUMBER);
    }
  }, [boxRef]);

  useImperativeHandle(ref, () => ({
    roll() {
      let newRollState: RollState = null;

      if (selected) {
        applyRollState(newRollState);
      } else {
        newRollState = getRollState(diceIndex, width, height);
        applyRollState(newRollState);
        setIsRolling(true);
      }

      onRoll(newRollState);
    },
  }));

  return (
    <mesh receiveShadow castShadow ref={boxRef}>
      <boxGeometry args={[1, 1, 1]} />
      {Array.from(Array(6)).map((_, i) => (
        <meshPhongMaterial attachArray="material" key={i} map={textures[i]} />
      ))}
    </mesh>
  );
});
