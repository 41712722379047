import React, { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  addPlayer,
  removePlayer,
  selectCurrentPlayer,
  selectIsGameInProgress,
  selectNumberOfRound,
  selectPlayers,
  setPlayerName,
} from "../../store/slices/gameSlice";
import { translate } from "../../services/i18n/i18n";
import {
  selectSelfPlayerId,
  setSelfPlayerId,
} from "../../store/slices/settingsSlice";
import { useIsOnlineMode } from "../../services/use-can-play-in-online-mode";
import { getPlayerUsername } from "../../services/get-player-username";
import { ReactComponent as AddPerson } from "../../images/person-add-outline.svg";

export function ConfigLine() {
  const dispatch = useAppDispatch();
  const isOnlineMode = useIsOnlineMode();
  const currentPlayer = useAppSelector(selectCurrentPlayer);
  const players = useAppSelector(selectPlayers);
  const isGameInProgress = useAppSelector(selectIsGameInProgress);
  const numberOfRound = useAppSelector(selectNumberOfRound);
  const selfPlayerId = useAppSelector(selectSelfPlayerId);

  const displaySelfAssignButton = useMemo(
    () => isOnlineMode && numberOfRound < 1,
    [isOnlineMode, numberOfRound]
  );

  return (
    <tr>
      <th>
        {isGameInProgress ? (
          currentPlayer && (
            <p style={{ lineHeight: 1.1 }}>
              <small className="has-text-weight-normal">
                {translate("turn")}
              </small>
              <br />
              {getPlayerUsername(currentPlayer)}
            </p>
          )
        ) : (
          <button
            className="button is-primary"
            onClick={() => {
              dispatch(addPlayer());
            }}
          >
            <AddPerson
              style={{ width: "22px", marginRight: "5px", marginLeft: "-7px" }}
            />
            {translate("add-player" as "add-player_one", {
              count: players.length,
            })}
          </button>
        )}
      </th>

      {players.map((player, playerIndex) => {
        return (
          <th key={playerIndex} id={`player-${player.id}`}>
            <div
              className={`field ${displaySelfAssignButton ? "has-addons" : ""}`}
            >
              <div
                className={`control ${
                  isGameInProgress ? "" : "has-icons-right"
                }`}
              >
                <input
                  type="text"
                  className="input is-primary player-input"
                  value={player.name}
                  placeholder={`${translate("player")} ${playerIndex + 1}`}
                  disabled={isGameInProgress}
                  onChange={({ target: { value } }) =>
                    dispatch(
                      setPlayerName({
                        id: player.id,
                        name: value,
                      })
                    )
                  }
                />
                {!isGameInProgress && (
                  <span
                    className="icon is-large is-right"
                    onClick={() =>
                      window.confirm(translate("delete-player")) &&
                      dispatch(removePlayer(player.id))
                    }
                  >
                    <i className="delete" />
                  </span>
                )}
              </div>

              {displaySelfAssignButton && (
                <div className="control">
                  <button
                    className={`button is-info ${
                      player.id === selfPlayerId ? "is-outlined" : ""
                    }`}
                    disabled={player.id === selfPlayerId}
                    onClick={() => dispatch(setSelfPlayerId(player.id))}
                  >
                    {translate("self-assign")}
                  </button>
                </div>
              )}
            </div>
          </th>
        );
      })}
    </tr>
  );
}
