import { PlaneProps, usePlane } from "@react-three/cannon";
import React, { useEffect } from "react";

export function Plane(
  props: PlaneProps & { color?: string; hasShadow: boolean; isSound?: boolean }
) {
  const { isSound } = props;
  const [ref] = usePlane(() => ({
    rotation: [-Math.PI / 2, 0, 0],
    ...props,
  }));

  useEffect(() => {
    if (ref.current && isSound !== undefined) {
      ref.current.userData.isSound = isSound;
    }
  }, [isSound, ref]);

  return (
    <mesh ref={ref} receiveShadow>
      <color args={["#171717"]} attach="material" />
      <planeGeometry args={[1000, 1000]} />
      {(props.hasShadow || false) && (
        <shadowMaterial transparent opacity={0.4} />
      )}
      {props.color && <meshPhysicalMaterial color={props.color} />}
    </mesh>
  );
}
