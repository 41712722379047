import { Combination } from "../../combination";
import { Category } from "../category";
import { DiceFace } from "../../dice-face";

export abstract class SimpleSum extends Category {
  abstract readonly diceNumber: DiceFace;

  isValid(combination: Combination): boolean {
    return combination.value.some((face) => face === this.diceNumber);
  }

  computeScore(combination: Combination): number {
    return combination.value.reduce((score, face) => {
      if (face === this.diceNumber) {
        return score + face;
      }

      return score;
    }, 0);
  }
}
