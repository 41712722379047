import {
  Category,
  Chance,
  Five,
  Four,
  FourOfAKind,
  FullHouse,
  LargeStraight,
  One,
  Six,
  SmallStraight,
  Three,
  ThreeOfAKind,
  Two,
  Yahtzee,
} from "./categories";

export const UPPER_SECTION_CATEGORIES: ReadonlyArray<Category> = [
  new One(),
  new Two(),
  new Three(),
  new Four(),
  new Five(),
  new Six(),
];

export const LOWER_SECTION_CATEGORIES: ReadonlyArray<Category> = [
  new ThreeOfAKind(),
  new FourOfAKind(),
  new FullHouse(),
  new SmallStraight(),
  new LargeStraight(),
  new Yahtzee(),
  new Chance(),
];

export const ALL_CATEGORIES: ReadonlyArray<Category> = [
  ...UPPER_SECTION_CATEGORIES,
  ...LOWER_SECTION_CATEGORIES,
];
