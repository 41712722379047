import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectCurrentCombination,
  selectNumberOfTries,
  setCurrentCombination,
} from "../../store/slices/gameSlice";
import { Combination } from "../../domain/combination";
import { Canvas } from "@react-three/fiber";
import { Physics } from "@react-three/cannon";
import { Borders } from "./Borders";
import { translate } from "../../services/i18n/i18n";
import { Dices, DicesActions } from "./Dices";
import { selectIsSound } from "../../store/slices/settingsSlice";
import { DiceFace } from "../../domain/dice-face";
import {
  useCanPlayInOnlineMode,
  useIsOnlineMode,
} from "../../services/use-can-play-in-online-mode";
import { RollState } from "./RollState";
import SocketContext from "../../services/contexts/SocketContext";
import ThemeContext from "../../services/contexts/ThemeContext";

const MAX_NUMBER_OF_TRIES = 3;

export interface DiceState<T = DiceFace | null> {
  selected: boolean;
  face: T;
}

function getInitialState(currentCombination: Combination | null): DiceState[] {
  if (currentCombination) {
    return currentCombination.value.map((c) => ({
      selected: false,
      face: c,
    }));
  }

  return [
    { selected: false, face: null },
    { selected: false, face: null },
    { selected: false, face: null },
    { selected: false, face: null },
    { selected: false, face: null },
  ];
}

export function Roller() {
  const dispatch = useAppDispatch();
  const isOnlineMode = useIsOnlineMode();
  const canPlayInOnlineMode = useCanPlayInOnlineMode();
  const numberOfTries = useAppSelector(selectNumberOfTries);
  const currentCombination = useAppSelector(selectCurrentCombination);
  const isSound = useAppSelector(selectIsSound);
  const socket = useContext(SocketContext);
  const [rollStates, setRollStates] = useState<RollState[]>([]);
  const [isRolling, setIsRolling] = useState(false);
  const theme = useContext(ThemeContext);

  const [dicesState, setDicesState] = useState<DiceState[]>(
    getInitialState(currentCombination)
  );
  const dicesRef = useRef<DicesActions>(null);

  useEffect(() => {
    if (currentCombination === null) {
      setDicesState(getInitialState(null));
    } else if (!canPlayInOnlineMode) {
      setDicesState(getInitialState(currentCombination));
    }
  }, [currentCombination, canPlayInOnlineMode]);

  const depsReloadOnlyIfFacesChanged = useMemo(
    () => dicesState.map((d) => d.face),
    [dicesState]
  );

  useEffect(() => {
    if (isDicesStateFilled(dicesState) && isRolling) {
      const newCombination = Combination.of(dicesState.map((d) => d.face));

      dispatch(setCurrentCombination(newCombination.value));
      setIsRolling(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depsReloadOnlyIfFacesChanged, isRolling]);

  useEffect(() => {
    if (isOnlineMode) {
      socket.on("roll", (data: RollState[]) => {
        setRollStates(data);

        if (!canPlayInOnlineMode) {
          setDicesState(getInitialState(null));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnlineMode, canPlayInOnlineMode]);

  function toggleSelectedDice(diceIndex: number) {
    const dicesStateToUpdate: DiceState[] = [...dicesState];
    dicesStateToUpdate[diceIndex].selected =
      !dicesStateToUpdate[diceIndex].selected;
    setDicesState(dicesStateToUpdate);
  }

  function setDiceState(diceIndex: number, face: DiceFace | null) {
    const dicesStateToUpdate: DiceState[] = [...dicesState];
    dicesStateToUpdate[diceIndex].face = face;
    setDicesState(dicesStateToUpdate);
  }

  function hasReachedMaxNumberOfTries() {
    return numberOfTries >= MAX_NUMBER_OF_TRIES;
  }

  function roll() {
    if (hasReachedMaxNumberOfTries()) {
      return;
    }
    setIsRolling(true);

    dicesState.forEach((d, i) => {
      if (!d.selected) {
        setDiceState(i, null);
      }
    });

    if (dicesRef.current) {
      dicesRef.current.roll();
    }
  }

  function onRoll(rollsState: RollState[]) {
    if (isOnlineMode) {
      socket.emit("roll", rollsState);
    }
  }

  return (
    <div className="is-flex-mobile is-reverse-mobile">
      <div className="roller--board__box">
        <div className="roller--board">
          <div className="roller--carpet">
            <Canvas
              shadows
              dpr={[1, 5]}
              camera={{
                position: [0, 30, 5],
                fov: 21,
              }}
            >
              <ambientLight intensity={0.3} />
              <spotLight
                position={[15, 40, 15]}
                angle={0.3}
                penumbra={1}
                intensity={0.5}
                castShadow
                shadow-mapSize-width={2048}
                shadow-mapSize-height={2048}
              />
              <Physics gravity={[0, -9.8 * 4, 0]} allowSleep={true}>
                <Borders isSound={isSound} />
                <Dices
                  ref={dicesRef}
                  dicesState={dicesState}
                  isRolling={isRolling}
                  onRoll={onRoll}
                  onRolled={(diceIndex, face) => setDiceState(diceIndex, face)}
                  rollStates={rollStates}
                  color={theme.color}
                />
              </Physics>
            </Canvas>
          </div>
        </div>
      </div>

      <div
        className={`roll-info has-text-centered ${
          !isRolling && currentCombination ? "roll-info--up" : ""
        }`}
      >
        <div className="dices-pedestal">
          {dicesState.map((diceState, diceIndex) => (
            <div key={diceIndex} className="dices-pedestal--item">
              <div
                onClick={() =>
                  currentCombination && toggleSelectedDice(diceIndex)
                }
                className={`dice ${
                  diceState.face ? `dice-${diceState.face} is-clickable` : ""
                } ${diceState.selected ? "dice--selected" : ""}`}
              />
            </div>
          ))}
        </div>

        <div className="my-4">
          <button
            onClick={roll}
            className="button is-glow is-dark roll-button is-flex is-flex-direction-column"
            disabled={
              hasReachedMaxNumberOfTries() || isRolling || !canPlayInOnlineMode
            }
          >
            <span className="has-text-weight-bold">{translate("roll")} 🎲</span>
            <span className="tag is-primary is-rounded">
              {numberOfTries} / {MAX_NUMBER_OF_TRIES}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

function isDicesStateFilled(
  dicesState: DiceState[]
): dicesState is DiceState<DiceFace>[] {
  return dicesState.every(
    (d: DiceState): d is DiceState<DiceFace> => d.face !== null
  );
}
