import React from "react";
import { translate } from "../../services/i18n/i18n";
import { toggleWelcomeModal } from "../../store/slices/settingsSlice";
import { useAppDispatch } from "../../store/hooks";

export function WelcomeModal() {
  const dispatch = useAppDispatch();

  function close() {
    dispatch(toggleWelcomeModal());
  }

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-content">
        <div className="box content">
          <p className="title">{translate("welcome-modal.title")}</p>
          <p>{translate("welcome-modal.first")}</p>

          <p className="subtitle">{translate("welcome-modal.tell-me-more")}</p>
          <p>{translate("welcome-modal.example-1")}</p>
          <p>{translate("welcome-modal.example-2")}</p>

          <p className="subtitle">{translate("welcome-modal.how-to")}</p>
          <p>{translate("welcome-modal.steps")}</p>

          <div className="has-text-centered">
            <button className="button is-primary is-large" onClick={close}>
              {translate("play")}
            </button>

            <small>
              <a
                href="https://tiste.io"
                className="has-text-primary-dark is-block mt-5"
              >
                {translate("created-by")}
              </a>
            </small>
          </div>
        </div>
      </div>

      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={close}
      />
    </div>
  );
}
