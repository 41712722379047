import { Category } from "../category";
import { Combination } from "../../combination";

export class Yahtzee extends Category {
  name = "Yahtzee";

  isValid(combination: Combination): boolean {
    const duplicates = combination.getDuplicates();

    return Array.from(duplicates.values()).some((number) => number === 5);
  }

  computeScore(_: Combination): number {
    return 50;
  }
}
