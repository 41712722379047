import React from "react";
import { translate } from "../../services/i18n/i18n";
import {
  selectIsSound,
  toggleSound,
  toggleWelcomeModal,
} from "../../store/slices/settingsSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { resetGame } from "../../store/slices/gameSlice";
import { Link } from "react-router-dom";
import { useIsOnlineMode } from "../../services/use-can-play-in-online-mode";
// import { v4 as uuid } from "uuid";
// import { ONLINE_PATHNAME } from "../../constants";

export function SettingsModal(props: { onClose: () => void }) {
  const { onClose } = props;
  const dispatch = useAppDispatch();
  const isSound = useAppSelector(selectIsSound);
  const isOnlineMode = useIsOnlineMode();

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-content">
        <div className="box">
          <div className="columns is-multiline">
            <div className="column is-6 settings-item">
              <button
                className="button is-primary"
                onClick={() => {
                  dispatch(resetGame());
                  onClose();
                }}
              >
                {translate("new-game")}
              </button>

              <small>{translate("menu.new-game-info")}</small>
            </div>
            <div className="column is-6 settings-item">
              <button
                className="button is-primary"
                onClick={() => dispatch(toggleSound())}
              >
                {isSound ? "🔉" : "🔇"}
              </button>
              <small>{translate("menu.sound-info")}</small>
            </div>
            <div className="column is-6 settings-item">
              <button
                className="button is-primary"
                onClick={() => dispatch(toggleWelcomeModal())}
              >
                {translate("help")}
              </button>
            </div>
            <div className="column is-6 settings-item">
              {isOnlineMode ? (
                <>
                  <Link className="button is-primary" to="/">
                    {translate("menu.local")}
                  </Link>
                  <small>{translate("menu.local-info")}</small>
                </>
              ) : (
                <>
                  <button
                    className="button is-primary"
                    disabled
                    // onClick={() => dispatch(resetGame())}
                    // to={`${ONLINE_PATHNAME}?room=${uuid()}`}
                  >
                    {translate("menu.online")}
                  </button>
                  <small>
                    {translate("menu.online-info")} baptiste.lecocq@gmail.com
                  </small>
                </>
              )}
            </div>
          </div>

          <div className="has-text-centered mt-6">
            <button className="button is-primary is-large" onClick={onClose}>
              {translate("close")}
            </button>

            <small>
              <a
                href="https://tiste.io"
                className="has-text-primary-dark is-block mt-5"
              >
                {translate("created-by")}
              </a>
            </small>
          </div>
        </div>
      </div>

      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={onClose}
      />
    </div>
  );
}
