import * as THREE from "three";

export function createDiceFaceTexture(image: HTMLImageElement, color: string) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Canvas not initialized");
  }
  const canvasTexture = new THREE.CanvasTexture(canvas);

  canvas.width = canvas.height = 128;
  context.fillStyle = color;
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.drawImage(
    image,
    0,
    0,
    image.width,
    image.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return canvasTexture;
}
