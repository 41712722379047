import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dice, DiceActions } from "./Dice";
import dice1 from "./assets/1.png";
import dice2 from "./assets/2.png";
import dice3 from "./assets/3.png";
import dice4 from "./assets/4.png";
import dice5 from "./assets/5.png";
import dice6 from "./assets/6.png";
import { DiceState } from "./Roller";
import { DiceFace } from "../../domain/dice-face";
import { RollState } from "./RollState";

interface DicesProps {
  dicesState: DiceState[];
  isRolling: boolean;
  onRoll: (rollStates: RollState[]) => void;
  onRolled: (diceIndex: number, face: DiceFace) => void;
  rollStates: RollState[];
  color: string;
}

export type DicesActions = {
  roll: () => void;
};

export const Dices = forwardRef(function Dices(
  props: DicesProps,
  ref: ForwardedRef<DicesActions>
) {
  const { dicesState, onRoll, onRolled, rollStates, isRolling, color } = props;
  const dices3D = useRef<DiceActions[]>([]);
  const [rollStatesToPropagate, setRollStates] = useState<RollState[]>([]);

  const diceImageFaces = useMemo(() => {
    return [dice1, dice2, dice3, dice4, dice5, dice6].map((d) => {
      const i = new Image();
      i.src = d;
      return i;
    });
  }, []);

  useEffect(() => {
    if (rollStatesToPropagate.length === 5 && isRolling) {
      onRoll(rollStatesToPropagate);
      setRollStates([]);
    }
  }, [rollStatesToPropagate, isRolling, onRoll]);

  useImperativeHandle(ref, () => ({
    roll() {
      dices3D.current.forEach((d) => d.roll());
    },
  }));

  return (
    <>
      {dicesState.map((diceState, i) => (
        <Dice
          ref={(r) => {
            if (r) {
              dices3D.current[i] = r;
            }
          }}
          key={i}
          diceIndex={i}
          selected={diceState.selected}
          onRoll={(rollState) =>
            setRollStates((arr) => {
              arr[i] = rollState;
              return arr;
            })
          }
          onRolled={(face) => onRolled(i, face)}
          imageFaces={diceImageFaces}
          rollState={rollStates[i]}
          color={color}
        />
      ))}
    </>
  );
});
