import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
import { Roller } from "./roller/Roller";
import { Sketch } from "./sketch/Sketch";
import { Nav } from "./Nav";
import Confetti from "react-confetti";
import { useAppSelector } from "../store/hooks";
import { selectIsGameFinished } from "../store/slices/gameSlice";
import { GameplayHooks } from "./GameplayHooks";
import { ThemeProvider } from "../services/contexts/ThemeContext";
import variables from "../stylesheets/variables.scss";
import {
  Orientation,
  useScreenOrientation,
} from "../services/use-screen-orientation";

export function Game() {
  const isGameFinished = useAppSelector(selectIsGameFinished);
  const [color, setColor] = useState(variables.primary);
  const orientation = useScreenOrientation();

  return (
    <ThemeProvider
      value={{
        color,
        setColor: (c: string) => setColor(c),
      }}
    >
      <Toaster
        toastOptions={{
          duration: 4000,
          style: {
            marginTop: "500px",
            minWidth: "500px",
            paddingTop: "50px",
            paddingBottom: "50px",
          },
          className: "title has-text-centered has-text-primary is-glow",
        }}
      />
      <Nav />
      <div className="section pt-5">
        <div
          className={`columns ${
            orientation === Orientation.landscape ? "is-mobile" : ""
          }`}
        >
          <div className="column column-sketch is-6">
            <Sketch />
          </div>

          <div className="column is-6">
            <Roller />
          </div>
        </div>
      </div>
      {isGameFinished && (
        <Confetti numberOfPieces={1000} height={document.body.offsetHeight} />
      )}
      <GameplayHooks />
    </ThemeProvider>
  );
}
