import React from "react";
import { useAppSelector } from "../../store/hooks";
import { selectPlayers } from "../../store/slices/gameSlice";
import { translate } from "../../services/i18n/i18n";
import CountUp from "react-countup";
import { Computation } from "../../domain/computation";

export function TotalLine() {
  const players = useAppSelector(selectPlayers);

  return (
    <tr className="has-background-primary-light">
      <td>
        <span className="has-text-weight-bold">{translate("total")}</span>
      </td>

      {players.map((player, playerIndex) => {
        return (
          <td key={playerIndex}>
            <span className="has-text-cursive" data-testid="total-score">
              <CountUp
                end={Computation.getTotalPlayerScore(player)}
                preserveValue={true}
              />
            </span>
          </td>
        );
      })}
    </tr>
  );
}
