import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { RootState } from "../store";
import { isEmpty } from "lodash";
import { Combination, PrimitiveCombination } from "../../domain/combination";
import { ALL_CATEGORIES } from "../../domain/all-categories";
import { Category } from "../../domain/categories";
import { Player, PlayerWithIndex } from "../../domain/player";
import { Computation } from "../../domain/computation";

export interface GameState {
  players: Player[];
  numberOfTries: number;
  currentCombination: PrimitiveCombination | null;
  currentPlayerId: string | null;
}

function getInitialState(): GameState {
  const id = uuid();

  return {
    players: [{ id: id, name: "", categories: {} }],
    numberOfTries: 0,
    currentCombination: null,
    currentPlayerId: id,
  };
}

export const gameSlice = createSlice({
  name: "game",
  initialState: getInitialState(),
  reducers: {
    resetGame: () => {
      return { ...getInitialState() };
    },
    overrideGame: (state, action: PayloadAction<GameState>) => {
      return action.payload;
    },
    addPlayer: (state) => {
      const id = uuid();

      if (!state.players.length) {
        state.currentPlayerId = id;
      }
      state.players.push({
        id: id,
        name: "",
        categories: {},
      });
    },
    removePlayer: (state, action: PayloadAction<string>) => {
      state.players = state.players.filter((p) => p.id !== action.payload);
    },
    setPlayerName: (
      state,
      action: PayloadAction<{ id: string; name: string }>
    ) => {
      const player = state.players.find((p) => p.id === action.payload.id);

      if (player) {
        player.name = action.payload.name;
      }
    },
    setPlayerScore: (
      state,
      action: PayloadAction<{
        category: Category;
        combination: Combination;
      }>
    ) => {
      const currentPlayer = state.players.find(
        (p) => p.id === state.currentPlayerId
      );

      if (currentPlayer) {
        currentPlayer.categories[action.payload.category.name] = {
          score: Computation.getCombinationScore(
            action.payload.category,
            action.payload.combination
          ),
          combination: action.payload.combination.value,
        };
      }
    },
    setCurrentCombination: (
      state,
      action: PayloadAction<PrimitiveCombination>
    ) => {
      state.currentCombination = action.payload;
      state.numberOfTries = state.numberOfTries + 1;
    },
    nextPlayer: (state) => {
      const currentPlayerIndex = state.players.findIndex(
        (p) => p.id === state.currentPlayerId
      );
      const nextIndex = (currentPlayerIndex + 1) % state.players.length;

      state.numberOfTries = 0;
      state.currentPlayerId = state.players[nextIndex].id;
      state.currentCombination = null;
    },
  },
});

export const {
  resetGame,
  overrideGame,
  addPlayer,
  removePlayer,
  setPlayerName,
  setPlayerScore,
  setCurrentCombination,
  nextPlayer,
} = gameSlice.actions;

export const selectIsGameFinished = (state: RootState) =>
  state.game.players.length &&
  state.game.players.every(
    (p) => Object.keys(p.categories).length === ALL_CATEGORIES.length
  );

export const selectIsGameInProgress = (state: RootState) =>
  !state.game.players.every((p) => isEmpty(p.categories));

export const selectNumberOfRound = (state: RootState) =>
  Math.min(...state.game.players.map((p) => Object.keys(p.categories).length));

export const selectCurrentPlayer = (
  state: RootState
): PlayerWithIndex | undefined => {
  const currentPlayerIndex = state.game.players.findIndex(
    (p) => p.id === state.game.currentPlayerId
  );

  if (currentPlayerIndex === -1) {
    return undefined;
  }

  return {
    ...state.game.players[currentPlayerIndex],
    index: currentPlayerIndex,
  };
};

export const selectBestPlayer = (
  state: RootState
): PlayerWithIndex | undefined => {
  if (!state.game.players.length) {
    return;
  }

  const bestPlayer = state.game.players.reduce((best, p) =>
    Computation.getTotalPlayerScore(p) > Computation.getTotalPlayerScore(best)
      ? p
      : best
  );
  if (!bestPlayer) {
    return;
  }

  const currentBestPlayerIndex = state.game.players.findIndex(
    (p) => p.id === bestPlayer.id
  );

  return {
    ...bestPlayer,
    index: currentBestPlayerIndex,
  };
};

export const selectPlayers = (state: RootState) => state.game.players;

export const selectNumberOfTries = (state: RootState) =>
  state.game.numberOfTries;

export const selectCurrentCombination = (
  state: RootState
): Combination | null =>
  state.game.currentCombination
    ? Combination.of(state.game.currentCombination)
    : null;

export const selectGame = (state: RootState) => state.game;

export default gameSlice.reducer;
