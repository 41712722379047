import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import gameReducer from "./slices/gameSlice";
import settingsReducer from "./slices/settingsSlice";
import storage from "redux-persist/lib/storage";
import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { MigrationManifest } from "redux-persist/es/types";

const rootReducer = combineReducers({
  game: gameReducer,
  settings: settingsReducer,
});

const migrations: MigrationManifest = {
  1: (state) => {
    return state;
  },
  2: (state) => {
    if (!state) {
      return undefined;
    }

    return { _persist: state._persist };
  },
  3: (state) => {
    if (!state) {
      return undefined;
    }

    return { _persist: state._persist };
  },
};

const persistConfig = {
  key: "root",
  version: 3,
  storage,
  migrate: createMigrate(migrations),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
