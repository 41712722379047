import { Player } from "./player";
import {
  LOWER_SECTION_CATEGORIES,
  UPPER_SECTION_CATEGORIES,
} from "./all-categories";
import { Category } from "./categories";
import { Combination } from "./combination";

const TEXT_TO_EMOJI = "_,1️⃣,2️⃣,3️⃣,4️⃣,5️⃣,6️⃣";

export interface PlayerBestScore {
  score: number;
  combination: string;
  categoryName: string;
}

export class Computation {
  static getCombinationScore(category: Category, combination: Combination) {
    if (category.isValid(combination)) {
      return category.computeScore(combination);
    }

    return 0;
  }

  static getUpperSectionPlayerScore(player: Player): number {
    return UPPER_SECTION_CATEGORIES.reduce((score, category) => {
      const playerScore = Computation.getPlayerScore(category, player);

      if (playerScore === null) {
        return score;
      }

      return score + playerScore;
    }, 0);
  }

  static getLowerSectionPlayerScore(player: Player): number {
    return LOWER_SECTION_CATEGORIES.reduce((score, category) => {
      const playerScore = Computation.getPlayerScore(category, player);

      if (playerScore === null) {
        return score;
      }

      return score + playerScore;
    }, 0);
  }

  static getTotalPlayerScore(player: Player): number {
    const subtotalPlayerScore = Computation.getUpperSectionPlayerScore(player);
    const lowerTotalPlayerScore =
      Computation.getLowerSectionPlayerScore(player);

    return (
      lowerTotalPlayerScore +
      subtotalPlayerScore +
      (subtotalPlayerScore >= 63 ? 35 : 0)
    );
  }

  static getPlayerBestScore(player: Player): PlayerBestScore {
    return Object.entries(player.categories).reduce(
      (bestScore, [categoryName, category]) => {
        if (bestScore.score < category.score) {
          return {
            score: category.score,
            combination: category.combination
              .map((face) => TEXT_TO_EMOJI.split(",")[face])
              .join(" "),
            categoryName: categoryName,
          };
        }

        return bestScore;
      },
      {
        score: 0,
        combination: "",
        categoryName: "",
      }
    );
  }

  static getPlayerScore(category: Category, player?: Player): number | null {
    if (!player) {
      return null;
    }

    const playerScoreForCategory = player.categories[category.name];

    if (playerScoreForCategory) {
      return playerScoreForCategory.score;
    }

    return null;
  }
}
