import { Category } from "../../domain/categories";
import { translate } from "../../services/i18n/i18n";
import { categoriesToClass } from "../../services/categories-configuration";
import React from "react";
import { UPPER_SECTION_CATEGORIES } from "../../domain/all-categories";

export function CategoryDisplay({ category }: { category: Category }) {
  return UPPER_SECTION_CATEGORIES.includes(category) ? (
    <div className="is-flex is-align-items-center">
      <span className="has-text-weight-bold">{translate("sum-of")}</span>
      <div
        className={`dice ml-3 ${
          categoriesToClass(category).cssClass
        } dice--small`}
      />
    </div>
  ) : (
    <div className="is-flex is-align-items-center">
      <span className="has-text-weight-bold">
        {translate(
          `combination.${category.name}.name` as "combination.ThreeOfAKind.name"
        )}
      </span>
    </div>
  );
}
