import { BoxProps } from "@react-three/cannon";

export type RollState = Required<
  Pick<BoxProps, "position" | "velocity" | "angularVelocity" | "rotation">
> | null;

export function getRollState(
  diceIndex: number,
  screenWidth: number,
  screenHeight: number
): RollState {
  const xRand = -1 * (Math.random() * 2 + 5);
  const yRand = -1 * Math.random() * 20;
  const zRand = -1 * (Math.random() * 2 + 4);

  const offsetScreen = 4;
  const xDelta = screenWidth / 2 - offsetScreen;
  const yDelta = 6;
  const zDelta = screenHeight / 2 - offsetScreen;

  const threeDicesInBottomOfHand = 3;
  return {
    position: [
      xDelta + ((diceIndex * 2) % 5),
      yDelta + (diceIndex < threeDicesInBottomOfHand ? 2 : 5),
      zDelta,
    ],
    velocity: [xRand, yRand, zRand],
    angularVelocity: [
      getAngularVelocity(),
      getAngularVelocity(),
      getAngularVelocity(),
    ],
    rotation: [
      Math.PI * Math.random() * 2,
      Math.PI * Math.random() * 2,
      Math.PI * Math.random() * 2,
    ],
  };
}

function getAngularVelocity() {
  return 50 * Math.random() - 25;
}
