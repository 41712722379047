import React from "react";
import { Plane } from "./Plane";
import { useThree } from "@react-three/fiber";

export function Borders(props: { isSound: boolean }) {
  const { isSound } = props;
  const { height, width } = useThree((state) => state.viewport);

  const offset = 0.5;

  return (
    <>
      <Plane
        position={[0, 0, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        hasShadow={true}
        isSound={isSound}
      />
      <Plane
        position={[-width / 2 + offset, 0, 0]}
        rotation={[0, Math.PI / 2, 0]}
        hasShadow={false}
      />
      <Plane
        position={[width / 2 - offset, 0, 0]}
        rotation={[0, -Math.PI / 2, 0]}
        hasShadow={false}
      />
      <Plane
        position={[0, 0, -height / 2 + offset]}
        rotation={[0, 0, 0]}
        hasShadow={false}
      />
      <Plane
        position={[0, 0, height / 2 - offset]}
        rotation={[0, -Math.PI, 0]}
        hasShadow={false}
      />
    </>
  );
}
