import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { Game } from "./components/Game";
import "./stylesheets/main.scss";
import { OnlineGame } from "./components/OnlineGame";
import { ONLINE_PATHNAME } from "./constants";

export function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Game />} />
        <Route path={ONLINE_PATHNAME} element={<OnlineGame />} />
        <Route path="*" element={<p>404 Page</p>} />
      </Routes>
    </Router>
  );
}
