import React from "react";

export const theme = {
  color: "",
  setColor: (c: string) => console.log(c),
};
const ThemeContext = React.createContext(theme);

export const ThemeProvider = ThemeContext.Provider;
export const ThemeConsumer = ThemeContext.Consumer;
export default ThemeContext;
