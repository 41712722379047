import { useEffect, useState } from "react";

export enum Orientation {
  landscape,
  portrait,
}

export function useScreenOrientation(): Orientation {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions.width > windowDimensions.height
    ? Orientation.landscape
    : Orientation.portrait;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
