import React from "react";
import { translate } from "../../services/i18n/i18n";
import QRCode from "react-qr-code";

export function QrCodeModal(props: { onClose: () => void }) {
  const { onClose } = props;

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-content">
        <div className="box has-text-centered">
          <QRCode value={window.location.href} />

          <p>{translate("show-qr-code")}</p>

          <div className="has-text-centered mt-6">
            <button className="button is-primary is-large" onClick={onClose}>
              {translate("play")}
            </button>
          </div>
        </div>
      </div>

      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={onClose}
      />
    </div>
  );
}
