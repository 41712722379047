import { Category } from "../domain/categories";
import { ALL_CATEGORIES } from "../domain/all-categories";

export function categoriesToClass(category: Category): {
  cssClass: string;
} {
  switch (category.name) {
    case ALL_CATEGORIES[0].name:
      return { cssClass: "dice-1" };
    case ALL_CATEGORIES[1].name:
      return { cssClass: "dice-2" };
    case ALL_CATEGORIES[2].name:
      return { cssClass: "dice-3" };
    case ALL_CATEGORIES[3].name:
      return { cssClass: "dice-4" };
    case ALL_CATEGORIES[4].name:
      return { cssClass: "dice-5" };
    case ALL_CATEGORIES[5].name:
      return { cssClass: "dice-6" };
    default:
      return { cssClass: "" };
  }
}
