import React, { useMemo } from "react";
import { translate } from "../../services/i18n/i18n";
import { getPlayerUsername } from "../../services/get-player-username";
import { useAppSelector } from "../../store/hooks";
import {
  selectBestPlayer,
  selectCurrentPlayer,
  selectPlayers,
} from "../../store/slices/gameSlice";
import { selectSelfPlayerId } from "../../store/slices/settingsSlice";
import { useIsOnlineMode } from "../../services/use-can-play-in-online-mode";
import { Player } from "../../domain/player";
import { Computation, PlayerBestScore } from "../../domain/computation";

export function WinnerModal(props: { onClose: () => void }) {
  const { onClose } = props;
  const isOnlineMode = useIsOnlineMode();
  const currentPlayer = useAppSelector(selectCurrentPlayer);
  const bestPlayer = useAppSelector(selectBestPlayer);
  const selfPlayerId = useAppSelector(selectSelfPlayerId);
  const players = useAppSelector(selectPlayers);

  const selfPlayer = useMemo(() => {
    return players.find((p) => p.id === selfPlayerId);
  }, [players, selfPlayerId]);

  const selfBestScore = useMemo(() => {
    if (selfPlayer) {
      return Computation.getPlayerBestScore(selfPlayer);
    }
  }, [selfPlayer]);

  const localBestScore = useMemo(() => {
    if (currentPlayer) {
      return Computation.getPlayerBestScore(currentPlayer);
    }
  }, [currentPlayer]);

  function share(bestPlayer: Player, localBestScore: PlayerBestScore) {
    const shareText = translate("share.tweet", {
      total: Computation.getTotalPlayerScore(bestPlayer),
      bestScore: localBestScore.score,
      categoryName: translate(
        `combination.${localBestScore.categoryName}.name` as "combination.One.name"
      ),
      combination: localBestScore.combination,
    });

    navigator.clipboard.writeText(shareText).finally(() => {
      window.open("https://twitter.com/intent/tweet?text=" + shareText);
    });
  }

  return (
    <div className="modal is-active">
      <div className="modal-content">
        <div className="box has-text-centered">
          {bestPlayer && localBestScore ? (
            <>
              <p className="title">
                {translate("winner")} {getPlayerUsername(bestPlayer)} 🎉
              </p>

              <p className="subtitle">
                {translate("share.local-text")}{" "}
                <span>
                  <strong>{localBestScore.score} pts</strong> (
                  {translate(
                    `combination.${localBestScore.categoryName}.name` as "combination.One.name"
                  )}
                  )
                  <br />
                  {localBestScore.combination}
                </span>
                <br />
                <button
                  className="button is-primary mt-3"
                  onClick={() => share(bestPlayer, localBestScore)}
                >
                  {translate("share.button")}
                </button>
              </p>
            </>
          ) : null}

          {isOnlineMode && selfBestScore && selfPlayer && (
            <>
              <hr />
              <p className="subtitle">
                {translate("share.online-text")}{" "}
                <span>
                  <strong>{selfBestScore.score} pts</strong> (
                  {translate(
                    `combination.${selfBestScore.categoryName}.name` as "combination.One.name"
                  )}
                  )
                  <br />
                  {selfBestScore.combination}
                </span>
                <br />
                <button
                  className="button is-primary mt-3"
                  onClick={() => share(selfPlayer, selfBestScore)}
                >
                  {translate("share.button-my-score")}
                </button>
              </p>
            </>
          )}

          <div className="has-text-centered mt-6">
            <button className="button is-primary is-small" onClick={onClose}>
              {translate("close")}
            </button>
          </div>
        </div>
      </div>

      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={onClose}
      />
    </div>
  );
}
