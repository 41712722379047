import React from "react";
import { useAppSelector } from "../../store/hooks";
import { selectPlayers } from "../../store/slices/gameSlice";
import { translate } from "../../services/i18n/i18n";
import CountUp from "react-countup";
import { Computation } from "../../domain/computation";

export function SubtotalLine() {
  const players = useAppSelector(selectPlayers);

  return (
    <>
      <tr className="has-background-primary-light">
        <td>
          <span className="has-text-weight-bold">{translate("subtotal")}</span>
        </td>

        {players.map((player, playerIndex) => {
          return (
            <td key={playerIndex}>
              <span className="has-text-cursive" data-testid="subtotal-score">
                <CountUp
                  end={Computation.getUpperSectionPlayerScore(player)}
                  preserveValue={true}
                />{" "}
                / 63
              </span>
            </td>
          );
        })}
      </tr>
      <tr className="has-background-primary-light">
        <td style={{ whiteSpace: "normal", lineHeight: "0.8" }}>
          <span className="has-text-weight-bold">
            {translate("bonus")}
            <br />
            <small style={{ fontSize: "0.7em" }}>
              {translate("bonus-description")}
            </small>
          </span>
        </td>

        {players.map((player, playerIndex) => {
          return (
            <td key={playerIndex}>
              <span className="has-text-cursive">
                {Computation.getUpperSectionPlayerScore(player) >= 63
                  ? "35"
                  : ""}
              </span>
            </td>
          );
        })}
      </tr>
    </>
  );
}
