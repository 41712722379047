import { Category } from "../category";
import { Combination } from "../../combination";
import { DiceFace } from "../../dice-face";

export class SmallStraight extends Category {
  name = "SmallStraight";

  isValid(combination: Combination): boolean {
    const allCombinations: DiceFace[][] = [
      [1, 2, 3, 4],
      [2, 3, 4, 5],
      [3, 4, 5, 6],
    ];

    return allCombinations.some((c) =>
      c.every((face) => combination.value.includes(face))
    );
  }

  computeScore(_: Combination): number {
    return 30;
  }
}
