import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface SettingsState {
  isSound: boolean;
  displayWelcomeModal: boolean;
  selfPlayerId: string | null;
  numberOfOnlinePlayers: number;
}

const initialState: SettingsState = {
  isSound: true,
  displayWelcomeModal: true,
  selfPlayerId: null,
  numberOfOnlinePlayers: 0,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    toggleSound: (state) => {
      state.isSound = !state.isSound;
    },
    toggleWelcomeModal: (state) => {
      state.displayWelcomeModal = !state.displayWelcomeModal;
    },
    setSelfPlayerId: (state, action: PayloadAction<string>) => {
      state.selfPlayerId = action.payload;
    },
    setNumberOfOnlinePlayers: (state, action: PayloadAction<number>) => {
      state.numberOfOnlinePlayers = action.payload;
    },
  },
});

export const {
  toggleSound,
  toggleWelcomeModal,
  setSelfPlayerId,
  setNumberOfOnlinePlayers,
} = settingsSlice.actions;

export const selectIsSound = (state: RootState) => state.settings.isSound;
export const selectDisplayWelcomeModal = (state: RootState) =>
  state.settings.displayWelcomeModal;
export const selectSelfPlayerId = (state: RootState) =>
  state.settings.selfPlayerId;
export const selectNumberOfOnlinePlayers = (state: RootState) =>
  state.settings.numberOfOnlinePlayers;

export default settingsSlice.reducer;
