import React, { useState } from "react";
import { SettingsModal } from "./modals/SettingsModal";
import { translate } from "../services/i18n/i18n";
import { useAppSelector } from "../store/hooks";
import { selectNumberOfOnlinePlayers } from "../store/slices/settingsSlice";
import { useIsOnlineMode } from "../services/use-can-play-in-online-mode";

export function Nav() {
  const isOnlineMode = useIsOnlineMode();
  const numberOfOnlinePlayers = useAppSelector(selectNumberOfOnlinePlayers);
  const [displaySettingsModal, setDisplaySettingsModal] = useState(false);

  return (
    <>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        {isOnlineMode && (
          <div
            className="button is-primary is-light has-tooltip-left"
            data-tooltip={translate(
              "number-of-online-players" as "number-of-online-players_one",
              {
                count: numberOfOnlinePlayers,
              }
            )}
          >
            {numberOfOnlinePlayers}

            <div className="status-indicator is-glow" />
          </div>
        )}

        <button
          className="button is-primary"
          onClick={() => setDisplaySettingsModal(true)}
        >
          {translate("menu.title")}
        </button>
      </nav>
      {displaySettingsModal && (
        <SettingsModal onClose={() => setDisplaySettingsModal(false)} />
      )}
    </>
  );
}
